
import { defineComponent } from "vue";
import { enUS, zhCN } from "@/plugins/i18n";
import Hero from "@/components/Hero/index.vue";
import VImage from "@/components/Image.vue";
import VSection from "@/components/VSection/index.vue";

export default defineComponent({
  name: "Contacts",

  components: {
    Hero,
    VImage,
    VSection,
  },

  computed: {
    companies: vm => [
      {
        icon: "about/shanghai.jpg",
        title: vm.$t("shanghai.title"),
        address: vm.$t("shanghai.address"),
        contact: vm.$t("shanghai.contact"),
        tel: vm.$t("shanghai.tel"),
        mail: "wang@als-yuchuan.com"
      },
      {
        icon: "about/zhuhai.jpg",
        title: vm.$t("zhuhai.title"),
        address: vm.$t("zhuhai.address"),
        contact: vm.$t("zhuhai.contact"),
        tel: vm.$t("zhuhai.tel"),
        mail: "michellezhang@als-yuchuan.com"
      },
      {
        icon: "about/dalian.jpg",
        title: vm.$t("dalian.title"),
        address: vm.$t("dalian.address"),
        contact: vm.$t("dalian.contact"),
        tel: vm.$t("dalian.tel"),
        mail: "ajia-no1@als-yuchuan.com"
      },
    ]
  },

  i18n: {
    messages: {
      [zhCN]: {
        title1: "联系我们",
        title2: "专业的设计、研发、工程、销售团队随时为您服务，欢迎您前来咨询与合作",
        organization: "组织机构",
        "shanghai.title": "上海玉串自动化科技有限公司",
        "shanghai.address": "上海市奉贤区解放东路1018弄1号楼1008室",
        "shanghai.contact": "王先生",
        "shanghai.tel": "13514118179",
        "zhuhai.title": "珠海分公司",
        "zhuhai.address": "广东省珠海市金湾区红旗镇藤山一路68号",
        "zhuhai.contact": "张女士",
        "zhuhai.tel": "18578268613",
        "dalian.title": "大连天瞳科技有限公司",
        "dalian.address": "辽宁省大连市开发区万达广场7号楼2217室",
        "dalian.contact": "贾先生",
        "dalian.tel": "15021309685",
      },
      [enUS]: {
        title1: "Contact us",
        title2: "Professional design, research and development, engineering, sales team at your service, you are welcome to come to consult and cooperation.",
        organization: "Organization",
        "shanghai.title": "ShangHai Company",
        "shanghai.address": "Room 1008, Building 1, Lane 1018 of JieFang East Road, FengXian District, ShangHai",
        "shanghai.contact": "Mr Wang",
        "shanghai.tel": "+86-13514118179",
        "zhuhai.title": "ZhuHai Company",
        "zhuhai.address": "No.68 TengShan 1st Road, HongQi Town, JinWan District, ZhuHai, GuangDong",
        "zhuhai.contact": "Miss Zhang",
        "zhuhai.tel": "+86-18578268613",
        "dalian.title": "DaLian Company",
        "dalian.address": "Room 2217, No.7 building of Wanda Plaza, Development Zone, Dalian,Liaoning",
        "dalian.contact": "Mr Jia",
        "dalian.tel": "+86-15021309685",
      }
    }
  }
});
